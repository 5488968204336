<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- begin::modal title -->
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar nueva área</span>
        </v-toolbar>
        <!-- end::modal title -->

        <!-- begin::modal content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.area.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Nombre de la área"
                    placeholder="Nombre de la área. Ej.: área 1"
                    required
                    filled
                    rounded
                    v-model="area.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="descripción" :validator="$v.area.description">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ingrese la descripción"
                    required
                    filled
                    rounded
                    v-model="area.description"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="área" :validator="$v.area.zone_id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="zones.isLoading"
                    :items="zones.data"
                    item-text="name"
                    item-value="id"
                    label="Zonas"
                    required
                    filled
                    rounded
                    v-model="area.zone_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="imagen">
                  <v-img
                    :src="image ? imagePreview : 'media/bg/default.png'"
                    style="object-fit: contain"
                    class="my-5 mx-auto rounded"
                    width="180"
                    height="180"
                  ></v-img>
                  <v-file-input
                    v-model="image"
                    type="file"
                    accept="image/*"
                    placeholder="Agregar imagen de la área"
                    label="Imagen"
                    required
                    filled
                    rounded
                    @change="selectImage"
                    @click:clear="clearImagePreview()"
                  ></v-file-input>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end::modal content -->

        <!-- begin::actions -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
        <!-- end::actions -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import areasRepository from "@/repositories/areasRepository";
import zoneRepository from "@/repositories/zoneRepository";
import { alphanumeric } from "@/core/plugins/vuelidate";

export default {
  name: "CreateAreaModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      image: null,
      imagePreview: "",
      zones: {
        data: [],
        isLoading: false,
      },
      area: {
        name: null,
        description: null,
        image: null,
        zone_id: null,
      },
    };
  },
  mounted() {
    this.loadZones();
  },
  methods: {
    loadZones() {
      zoneRepository
        .getAllZones()
        .then(({ data }) => {
          this.zones.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.zones.isLoading = false;
        });
    },
    create() {
      this.area.image = this.image;
      this.isLoading = true;
      areasRepository
        .createArea(this.objectToFormData(this.area))
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          this.$emit("add", { obj: data.data });
          this.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.fireToast({
              icon: "error",
              title: "No ha sido posible crear el registro 🙁",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor 🙁",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetArea();
    },

    resetArea() {
      this.area.name = null;
      this.area.description = null;
      this.area.zone_id = null;
      this.clearImagePreview();
    },
    //image selection and cleanup functions
    async selectImage(e) {
      const file = e;
      if (!file) return;
      const readData = (f) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(f);
        });

      const data = await readData(file);
      this.imagePreview = data;
    },
    async clearImagePreview() {
      this.imagePreview = "";
      this.image = null;
    },
  },
  validations: {
    area: {
      name: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
      description: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
      zone_id: {
        required,
      },
    },
  },
};
</script>
